import React from 'react'
import './Logo.scss'

export default () => (
  <div
    className="Logo"
  >
    <img src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1613490418/plastic-logo_dd6ls8.jp2" alt="Plastic Wax" className="img-fluid" />
  </div>
)
