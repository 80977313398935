import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import Logo from './Logo'

import './Nav.scss'
import Fade from './transition/Fade'

export class Navigation extends Component {
  state = {
    active: this.props.location.pathname,
    activeSubNav: false,
    currentPath: false,
    isOpen: false,
    firstLoad: true,
    navBackground: "transparent-nav" 
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      let prevScrollpos = window.pageYOffset;
      window.onscroll = () => {
        const maxScroll = document.body.clientHeight - window.innerHeight;
        let currentScrollPos = window.pageYOffset;
        if (
            (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll) 
          || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
          || (prevScrollpos <= 0 && currentScrollPos <= 0)
          ) {
            if(currentScrollPos === 0){
              this.setState({
                navBackground: "transparent-nav"
              })
            }else{
              for (const s of document.getElementsByClassName("navbar")) {
                if(s.offsetWidth){
                  s.style.cssText = "top: 0px"; 
                  this.setState({
                    navBackground: "dark-nav"
                  })
                }
              }
            }
        } else {
          for (const s of document.getElementsByClassName("navbar")) {
            if(s.offsetWidth){
              this.setState({
                navBackground: "transparent-nav"
              })
              s.style.top = "-60px";
            }
          }
        }
        prevScrollpos = currentScrollPos;
      }
    }
  }

  handleToggle = () => {    
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      firstLoad: true
    }))
  }

  toggleSubNav = subNav =>
  this.setState({
    activeSubNav: this.state.activeSubNav === subNav ? false : subNav
  })
  keyToggleSubNav = (e, subNav) => {
    // key o is for open so you can enter key to open
    if (e.keyCode === 79 || e.keyCode === 27) {
      this.toggleSubNav(subNav)
    }
  }
  render() {
    let menuStyle = {};
    if(!this.state.isOpen){
      menuStyle = {
        transform: 'scaleX(0)',
      }
    }else{
      menuStyle = {
        transform: 'scaleX(1)',
      }
    }
    return (
      <nav
        className={`navbar top-nav d-flex align-items-center justify-content-between ${this.state.navBackground}`}
        id="common-px"
      >
        <div className="logo-container">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        {this.state.firstLoad && (
          <div className="d-lg-none">
            <div className={this.state.isOpen ? "nav-button open": "nav-button"} aria-hidden="true" onClick={this.handleToggle}>
              <div className={this.state.isOpen ? "nav-button-child": "nav-button-close-child"}>
              </div>
              <div className={this.state.isOpen ? "nav-button-child": "nav-button-close-child"}>
              </div>
              <div className={this.state.isOpen ? "nav-button-child": "nav-button-close-child"}>
              </div>
            </div>
            <div
              className="mob-container d-flex flex-column align-items-center"
              style={menuStyle}
            >
              <div
                aria-hidden="true"
              >
              </div>
              <div className="menu-text">MENU</div>
              <div className="mob-link-container">
                <div>
                  <Fade spy={this.state.isOpen} timeout={1000}>
                    <Link to="/about" className="top-nav-link">
                      About Us
                    </Link>
                  </Fade>
                </div>
                <div>
                  <Fade spy={this.state.isOpen} timeout={1000}>
                    <Link to="/services" className="top-nav-link">
                      Services
                    </Link>
                  </Fade>
                </div>
                <div>
                  <Fade spy={this.state.isOpen} timeout={1000}>
                    <Link to="/projects" className="top-nav-link">
                      Projects
                    </Link>
                  </Fade>
                </div>
                <div>
                  <Fade spy={this.state.isOpen} timeout={1000}>
                    <Link to="/careers" className="top-nav-link">
                      Careers
                    </Link>
                  </Fade>
                </div>
                <div>
                  <Fade spy={this.state.isOpen} timeout={1000}>
                    <Link to="/contact" className="top-nav-link">
                      Contact
                    </Link>
                  </Fade>
                </div>
              </div>

              <div className="mob-menu-img">
                <img
                  src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614154622/menu-angle_hdhmid.svg"
                  alt="menu-angle"
                  className="d-block"
                />
              </div>
            </div>
          </div>
        )}

        <div className="desktop-link-container d-none d-lg-block">
          <Link
            to="/"
            className={
              (this.state.active === '//' || this.state.active === '/')
                ? 'active top-nav-link'
                : 'top-nav-link'
            }
          >
            Home
          </Link>
          <Link
            to="/about"
            className={
              this.state.active === '/about/' ||
              this.state.active === '/about' ||
              this.state.active === '/services/' ||
              this.state.active === '/services'
                ? 'active top-nav-link'
                : 'top-nav-link'
            }
          >
            Company
            <div className="inner-main-container">
              <div className="inner-link-container">
                <Link to="/about" 
                  className={
                    this.state.active === '/about/' ||
                    this.state.active === '/about'
                      ? 'active inner-link'
                      : 'inner-link'
                  }>
                  About Us
                </Link>
                <Link to="/services" 
                 className={
                  this.state.active === '/services/' ||
                  this.state.active === '/services'
                    ? 'active inner-link'
                    : 'inner-link'
                }>
                  Services
                </Link>
              </div>
            </div>
          </Link>
          <Link
            to="/our-team"
            className={
              this.state.active === '/careers/' ||
              this.state.active === '/careers' ||
              this.state.active === '/our-team/' ||
              this.state.active === '/our-team'
                ? 'active top-nav-link'
                : 'top-nav-link'
            }
          >
            Teams
            <div className="inner-main-container">
              <div className="inner-link-container">
                <Link to="/careers" 
                  className={
                    this.state.active === '/careers/' ||
                    this.state.active === '/careers'
                      ? 'active inner-link'
                      : 'inner-link'
                  }>
                  Careers
                </Link>
                <Link to="/our-team"
                className={
                  this.state.active === '/our-team/' ||
                  this.state.active === '/our-team'
                    ? 'active inner-link'
                    : 'inner-link'
                }>
                  Meet Us
                </Link>
              </div>
            </div>
          </Link>
          <Link
            to="/projects"
            className={
              this.state.active === '/projects/' ||
              this.state.active === '/projects'
                ? 'active top-nav-link'
                : 'top-nav-link'
            }
          >
            Projects
            <div className="inner-main-container">
              <div className="inner-link-container">
                <Link to="/projects"
                className={
                  this.state.active === '/projects/' ||
                  this.state.active === '/projects'
                    ? 'active inner-link'
                    : 'inner-link'
                }>
                  All Projects
                </Link>
              </div>
            </div>
          </Link>
          <Link
            to="/contact"
            className={
              this.state.active === '/contact/' ||
              this.state.active === '/contact'
                ? 'active top-nav-link'
                : 'top-nav-link'
            }
          >
            Contact
          </Link>
        </div>
        {/* <div className="menu-bar-container d-lg-none">
          <img
            aria-hidden={true}
            onClick={this.handleToggle}
            src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614154622/menu-bar_wi2z2z.png"
            alt="menu-bar"
            className="img-fluid"
          />
        </div> */}
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
